import React, { useState, useEffect } from "react";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import logo from "../images/logo.png";

const Footer = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const iconSize = windowWidth < 600 ? 20 : 30;

  return (
    <div>
      <div className="bg-myred flex px-[5%] py-3 lg:py-5 justify-between lg:px-[10%]">
        <div className="font-montserrat text-xs md:text-sm text-white pr-5">
          <div className="w-12 h-12 md:w-14 md:h-14 mb-2">
            <img
              src={logo}
              alt="AKL Group"
              className="rounded-full p-0.5 bg-white"
            />
          </div>
          <div className="font-montserrat text-white text-xs md:text-sm">
            <p alt="AKL address" className="mb-1">
              Lot 4467, Jalan Jedok, 17500 Tanah Merah, Kelantan
            </p>
            <p alt="Ang Kim Lin phone">09-955 5828/955 7828</p>
          </div>
          <div className="flex items-center text-white ">
            <div className="my-2 md:my-3 hover:text-myblue" alt="AKL facebook">
              <a href="https://www.facebook.com/AKLGroupTM/" target="blank">
                <FaFacebook size={iconSize} />
              </a>
            </div>
            <div
              className="ml-5 my-2 md:my-3 hover:text-myblue"
              alt="Ang kim lin facebook"
            >
              <a
                href="https://www.google.com/maps/place/Ang+Kim+Lin+Group/@5.8154703,102.1130876,15z/data=!4m2!3m1!1s0x0:0x93f57cd42819c97e?sa=X&ved=2ahUKEwjg6Yaez96CAxXwcWwGHRiBB7UQ_BJ6BAhOEAA"
                target="blank"
              >
                <FaGoogle size={iconSize} />
              </a>
            </div>
          </div>
        </div>

        <div className="font-montserrat text-white text-xs md:text-sm">
          <h1 className="text-white font-familjen font-bold text-lg lg:text-xl xlg:text-2xl mb-1">
            Our Companies
          </h1>
          <h1 alt="Ang Kim Lin Sdn Bhd" className="pb-1">
            Ang Kim Lin Sdn Bhd
          </h1>
          <h1 alt="Ang Kim Lin Distributors Sdn Bhd" className="pb-1">
            Ang Kim Lin Distributors Sdn Bhd
          </h1>
          <h1 alt="Ang Kim Lin Distributors(TRG) Sdn Bhd" className="pb-1">
            Ang Kim Lin Distributors (TRG) Sdn Bhd
          </h1>
          <h1 alt="Ang Kim Lin Marketing Sdn Bhd" className="pb-1">
            Ang Kim Lin Marketing Sdn Bhd
          </h1>
          <h1 alt="AKL Plantation" className="pb-1">
            AKL Plantation
          </h1>
          <h1 alt="Vision Distributors Sdn Bhd">Vision Distributors Sdn Bhd</h1>
        </div>
      </div>

      <div className="font-montserrat text-center my-3 md:my-5 text-myred text-xs md:text-sm">
        &copy;2024 by ANG KIM LIN GROUP.
      </div>
    </div>
  );
};

export default Footer;
