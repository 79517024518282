import React, { useState, useEffect } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import logo from "../images/logo.png";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();

  const isActive = (pathname) => {
    return location.pathname === pathname
      ? "bg-myblue border-myblue text-white"
      : "";
  };
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const handleScroll = () => {
    setNav(false);
  };

  const closeNavIfWideScreen = () => {
    if (window.innerWidth > 1024) {
      setNav(false);
    }
  };

  useEffect(() => {
    const handleNavbar = () => {
      const navbar = document.querySelector(".sticky-navbar");
      const scrollPosition = window.scrollY;

      if (scrollPosition > 1000) {
        // Adjust this value to the scroll position you desire
        navbar.classList.remove("sticky");
      } else {
        navbar.classList.add("sticky");
      }
    };

    window.addEventListener("scroll", handleNavbar);
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", closeNavIfWideScreen);
    return () => {
      window.removeEventListener("scroll", handleNavbar);
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", closeNavIfWideScreen);
    };
  }, []);

  return (
    <div className="sticky-navbar sticky top-0 flex justify-between items-center h-16 md:h-20 lg:h-24 xl:h-28 mx-auto pr-8 text-myred bg-white z-20 shadow-lg">
      <Link to="/">
        <div className="flex items-center pl-3 md:pl-6 lg:pl-12">
          <img
            src={logo}
            alt="/"
            className="object-fill h-10 w-10 md:h-14 md:w-14 xl:h-20 xl:w-20"
          />
          <h1 className="font-familjen text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold pl-2 xl:pl-4">
            Ang Kim Lin Group
          </h1>
        </div>
      </Link>

      <ul className="font-familjen text-md xl:text-xl hidden lg:flex lg:gap-1">
        <Link to="/">
          <li
            className={`flex-none p-3 hover:bg-myred hover:text-white ${isActive(
              "/"
            )}`}
          >
            Home
          </li>
        </Link>
        <Link to="/about">
          <li
            className={`flex-none p-3 hover:bg-myred hover:text-white ${isActive(
              "/about"
            )}`}
          >
            About Us
          </li>
        </Link>

        <Link to="/services">
          <li
            className={`flex-none p-3 hover:bg-myred hover:text-white ${isActive(
              "/services"
            )}`}
          >
            Our Services
          </li>
        </Link>

        <Link to="/products">
          <li
            className={`flex-none p-3 hover:bg-myred hover:text-white ${isActive(
              "/products"
            )}`}
          >
            Our Products
          </li>
        </Link>
        <Link to="/contact">
          <li
            className={`flex-none p-3 hover:bg-myred hover:text-white ${isActive(
              "/contact"
            )}`}
          >
            Contact Us
          </li>
        </Link>
      </ul>

      <div onClick={handleNav} className="block lg:hidden">
        {!nav ? <AiOutlineMenu size={20} /> : <AiOutlineClose size={20} />}
      </div>

      <div
        className={`${
          nav
            ? "z-10 fixed right-0 top-16 md:top-20 w-[35%] md:w-[30%] text-sm md:text-lg ease-in-out duration-500"
            : "fixed right-[-100%]"
        } `}
      >
        <ul className="font-familjen text-md md:text-lg uppercase bg-gray-100">
          <Link to="/">
            <li
              className={`p-5 border-b border-t border-l hover:bg-myred hover:text-white hover:border-myred ${isActive(
                "/"
              )}`}
            >
              Home
            </li>
          </Link>
          <Link to="/about">
            <li
              className={`p-5 border-b hover:bg-myred hover:text-white hover:border-myred ${isActive(
                "/about"
              )}`}
            >
              About Us
            </li>
          </Link>
          <Link to="/services">
            <li
              className={`p-5 border-b hover:bg-myred hover:text-white hover:border-myred ${isActive(
                "/services"
              )}`}
            >
              Our Services
            </li>
          </Link>

          <Link to="/products">
            <li
              className={`p-5 border-b hover:bg-myred hover:text-white hover:border-myred ${isActive(
                "/products"
              )}`}
            >
              Our Products
            </li>
          </Link>

          <li
            className={`p-5 border-b hover:bg-myred hover:text-white hover:border-myred ${isActive(
              "/contact"
            )}`}
          >
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
