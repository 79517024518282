import React, { Suspense, lazy, useEffect } from "react";
import Navbar from "./components/Navbar";
import { Routes, Route, useLocation } from "react-router-dom";
import Footer from "./components/Footer";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Home = lazy(() => import("./components/Home"));
const About = lazy(() => import("./components/About"));
const Service = lazy(() => import("./components/Service"));
const Product = lazy(() => import("./components/Product"));
const Contact = lazy(() => import("./components/Contact"));

function App() {
  return (
    <div>
      <Navbar />
      <ScrollToTop />
      <Suspense fallback={<div className="m-[50%]">Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Service />} />
          <Route path="/products" element={<Product />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Suspense>
      <Footer />
    </div>
  );
}

export default App;
